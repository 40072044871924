import ProductsList from '@/components/sections/ProductsList';
import dynamic from 'next/dynamic';

const FeaturedTemplatesSprinkles = dynamic(
  () => import('assets/jsx-icons/FeaturedTemplatesSprinkles'),
  { ssr: false },
);

export default function FeaturedTemplates({ featuredProducts }) {
  return (
    <>
      <div className="featured-templates-block p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="home-products-block">
                <div className="background">
                  <FeaturedTemplatesSprinkles />
                </div>
                <h3>
                  Featured <label>Templates</label>
                </h3>
                <p>of thousands of graphics. Try one out!</p>
                <ProductsList products={featuredProducts} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
