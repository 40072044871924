import { createContext, useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import ApiService from 'services/ApiService';

export const HomePageContext = createContext();

export function HomePageProvider({ children }) {
  const [projectsData, setProjectsData] = useState('');

  const { data: session, status } = useSession();

  const getOwnProjects = async () => {
    if (session != null) {
      const { data } = await ApiService.instance.post('/api/gethomeprojects', {
        userid: session.user.id,
      });
      setProjectsData(data.data.saveddesigns);
    }
  };

  useEffect(() => {
    if (status === 'authenticated') {
      getOwnProjects();
    }
  }, [session, status]);

  return (
    <HomePageContext.Provider value={{ projectsData }}>
      {children}
    </HomePageContext.Provider>
  );
}
