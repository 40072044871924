const KosherAIIcon = () => {
  return (
    <>
      <svg
        width="35"
        height="37"
        viewBox="0 0 35 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="auto_awesome_black_24dp">
          <path
            id="Path 1286"
            d="M28.0013 14.2427L29.8763 10L34.0013 8.07152L29.8763 6.14303L28.0013 1.90036L26.1263 6.14303L22.0013 8.07152L26.1263 10L28.0013 14.2427ZM16.7513 15.0141L13.0013 6.52873L9.25134 15.0141L1.00134 18.871L9.25134 22.728L13.0013 31.2133L16.7513 22.728L25.0013 18.871L16.7513 15.0141ZM28.0013 23.4994L26.1263 27.7421L22.0013 29.6706L26.1263 31.599L28.0013 35.8417L29.8763 31.599L34.0013 29.6706L29.8763 27.7421L28.0013 23.4994Z"
            fill="url(#paint0_linear_1761_4879)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1761_4879"
            x1="-0.674848"
            y1="-9.05541"
            x2="30.4341"
            y2="-8.75463"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="var(--kg-secondary)" />
            <stop offset="1" stopColor="var(--kg-primary)" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
};

export default KosherAIIcon;
