import { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';

const CleanKosherIcon = dynamic(
  () => import('assets/jsx-icons/CleanKosherIcon'),
  { ssr: false },
);

const CleanKosherBlock = () => {
  const [initialLoad, setInitalLoad] = useState(false);

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.75,
    triggerOnce: true,
  };

  const { ref, inView, entry } = useInView(options);

  useEffect(() => {
    setInitalLoad(true);
  }, []);

  return (
    <>
      <div className="cleankosher-block p-100" ref={ref}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="cleankosher-blockinner">
                <h4>
                  Safer for your{' '}
                  <label className="gradient-text">Neshama</label>
                </h4>
                <p>
                  than canva. Everything here is kosher to high Torah standards
                </p>
                <span>{initialLoad && inView && <CleanKosherIcon />}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CleanKosherBlock;
