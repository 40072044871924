import { InstantSearch, Configure, Index } from 'react-instantsearch-dom';
import HomeSearchBox from './HomeSearchBox';
import HomeSearchHits from './HomeSearchHits';
import HomeSearchCatHits from './HomeSearchCatHits';
import { useSiteInfo } from '@/context/SiteInfoContext';
import algoliasearch from 'algoliasearch';
import {
  NEXT_PUBLIC_ALGOLIA_APP_ID,
  NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
} from '@/constants/publicConstants';

export default function Search() {
  const { siteInfo } = useSiteInfo();
  const searchClient = algoliasearch(
    NEXT_PUBLIC_ALGOLIA_APP_ID,
    NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY,
  );

  return (
    <>
      {searchClient && (
        <InstantSearch
          searchClient={searchClient}
          indexName={
            siteInfo.algolia_products_indexname
              ? siteInfo.algolia_products_indexname
              : process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEXNAME
          }
        >
          <HomeSearchBox />

          <div className="homesearch-result">
            <ul>
              <Index
                indexName={
                  siteInfo.algolia_products_indexname
                    ? siteInfo.algolia_products_indexname
                    : process.env.NEXT_PUBLIC_ALGOLIA_PRODUCTS_INDEXNAME
                }
              >
                <Configure hitsPerPage={5} />
                <HomeSearchHits />
              </Index>
            </ul>
          </div>
        </InstantSearch>
      )}
    </>
  );
}
