import { toast } from 'react-toastify';

export function replaceSlugIfNeeded(slug) {
  const baseURL = 'https://koshergraphics.com/';

  // Check if the slug starts with the baseURL
  if (slug.startsWith(baseURL)) {
    // If it does, replace the baseURL with an empty string
    return slug.replace(baseURL, '');
  }

  // If the slug does not start with the baseURL, return the original slug
  return slug;
}

export function removeDecimals(inputString) {
  // Check if inputString is not null, not undefined, not "0", and is a string
  if (
    inputString !== null &&
    inputString !== undefined &&
    inputString !== '0' &&
    typeof inputString === 'string'
  ) {
    // Use a regular expression to remove decimal parts
    return inputString.replace(/\.\d+/g, '').replace(/\$/g, '');
  } else {
    // Return inputString unchanged if it's null, undefined, "0", or not a string
    return inputString;
  }
}

export function formatPrice(price) {
  // Check if the input is null
  if (price === null) {
    // If it's null, return $0
    return 'Free';
  }

  if (price === 0 || price === '0') return 'Free';

  // Check if the input is a string, number, or null
  if (typeof price === 'string' || typeof price === 'number') {
    // If it's a string or a number, convert it to a string for further processing
    price = price.toString();
  } else {
    // If it's neither a string nor a number, return as is
    return price;
  }

  // Check if the price is equal to zero
  if (parseFloat(price) === 0) {
    // If it's zero, return "$0"
    return '$0';
  }

  // Check if the price starts with "$"
  if (!price.startsWith('$')) {
    // If not, add "$" at the beginning
    return '$' + price;
  }
  // If it already starts with "$", return as is
  return price;
}

export const copyUrl = async (url) => {
  navigator.clipboard.writeText(url);
  toast.success('The Link was copied');
};

// sanitizes the reponse of openai, removes code blocks
export default function removeTokens(string) {
  const regex = /##(.*?)##/g;
  return string.replace(regex, '');
}

export const removeTags = (inputText) => {
  try {
    if (!inputText) return inputText;
    var result = inputText.replace(/<\/?p>/g, '');
    return result;
  } catch (e) {
    return inputText;
  }
};

export function capitalizeFirstChar(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
