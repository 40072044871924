import Image from 'next/image';
import manydesignlayoutsblockimg from '@/images/homepage/manydesignlayoutsblockimg.webp';
import manydesignlayoutsblockimgmobile from '@/images/homepage/manydesignlayoutsblockimg-mobile.webp';

const ManyDesignLayoutsBlock = () => {
  return (
    <>
      <div className="manydesignlayouts-block p-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="manydesignlayouts-blockinner">
                <div className="manydesignlayouts-left">
                  <div className="manydesigns-img-container">
                    <Image
                      alt="manydesignlayoutsblockimg"
                      src={manydesignlayoutsblockimg}
                    />
                  </div>
                  <span className="manydesignlayouts-mobileimg">
                    <Image
                      className="mobile-img"
                      alt="manydesignlayoutsblockimg-mobile"
                      src={manydesignlayoutsblockimgmobile}
                    />
                  </span>
                </div>
                <div className="manydesignlayouts-right">
                  <h4>
                    Multiple <span className="gradient-text">Layouts</span>
                  </h4>
                  <p>Template layouts for all platforms and sizes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManyDesignLayoutsBlock;
