import WavesImage from '@/images/homepage/waves.svg';
import CircleImage from '@/images/homepage/circle-gradient.svg';
import Image from 'next/image';
import { ReactSVG } from 'react-svg';
import T5Logo from '@/images/homepage/5t.png';
import AnashLogo from '@/images/homepage/anash.png';
import ChabadOneLogo from '@/images/homepage/chabadone.png';
import ChayenuLogo from '@/images/homepage/chayenu.png';
import ColelChabadLogo from '@/images/homepage/colel-chabad.png';
import GateOfTrustLogo from '@/images/homepage/gate-of-trust.png';
import ShluchimDesignLogo from '@/images/homepage/shluchim-design.png';

const Partners = [
  {
    img: AnashLogo,
    name: 'Anash',
  },
  {
    img: T5Logo,
    name: '5 Towns Jewish Times',
  },
  {
    img: GateOfTrustLogo,
    name: 'Gate of Trust',
  },
  {
    img: ShluchimDesignLogo,
    name: 'Shluchim Office',
  },
  {
    img: ChabadOneLogo,
    name: 'ChabadOne',
  },
  {
    img: ChayenuLogo,
    name: 'Chayenu',
  },
  {
    img: ColelChabadLogo,
    name: 'Colel Chabad',
  },
];

const PartnersBlock = () => {
  return (
    <>
      <div className="partners-block p-100" id="partners">
        <div className="container">
          <div className="text-container">
            <h4>
              Our <span className="partners-text-container">Partners</span>
            </h4>
          </div>
          <div className="partners-container">
            <div className="partners-left-icons-container">
              <ReactSVG src={CircleImage.src} />
              <ReactSVG src={WavesImage.src} />
            </div>
            <div className="partners-right-icons-container">
              <ReactSVG src={WavesImage.src} />
            </div>
            <div className="image-container">
              <div className="partners-card">
                {Partners.map((partner) => (
                  <div key={partner.name} className="card-image">
                    <Image src={partner.img} alt={`${partner.name} Logo`} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnersBlock;
